import { graphql } from "gatsby"
import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SeoMeta from "../components/seo"
import { EccBullets } from "../components/ecc-bullets"

export const query = graphql`
  query {
    file(relativePath: { eq: "2019-20 Preschool Photo Shoot-211.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 400, height: 600, quality: 100)
      }
    }
  }
`

const AboutUsPage = ({data}) => {
  const image = getImage(data.file)

  return (
    <Layout>
      <SeoMeta title="About Us" />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="d-lg-none">
              <GatsbyImage
                alt="A young man holding a lady bug with plastic tongs"
                image={image}
                style={{
                  borderRadius: "10px",
                  margin: "0 0 0.8rem 0",
                  width: "100%",
                  height: "300px"
                }}
              />
            </div>
            <div className="d-none d-lg-block" style={{ float: "right", margin: "3rem 0.8rem 0 0.8rem" }}>
              <GatsbyImage
                alt="A young man holding a lady bug with plastic tongs"
                image={image}
                style={{borderRadius: "10px", width: "400px", height: "600px"}}
              />
            </div>
            <h3>About Us</h3>
            <p>Valley Schools is a WASC accredited private independent K-8th grade school &amp; Early Childhood Center located in the heart of the San Fernando Valley. The school was founded in 1950 and established on the premise of providing a quality education at a reasonable price. Over 70 years later, Valley School maintains that same philosophy and has transformed into a 21st century school incorporating modern day technology while maintaining the core values and culture.</p>
            {/* <p>Valley Schools is a WASC accredited private independent school in the heart of the San Fernando Valley. The school serves children from preschool through 8th grade and was founded in 1950 by Glenn and Jean Williams.  The school was originally called “The Valley Schools of Individual Training” and was later shortened to “Valley School”.  The Valley School crest was recently created using the previous logos and school symbols as a tribute to Valley School’s rich history.  The school was established on the premise of providing a quality education at a reasonable price. Over 69 years later, Valley School maintains that same philosophy and has transformed into a 21st century school incorporating modern day technology while maintaining the core values and culture that were first established by Glenn and Jean Williams.&nbsp;</p> */}
            {/* <h3>School History</h3>
            <p>In 1950, the school started out in a home close to Haskell Ave and Vanowen St.  The school started with 7 students and had 25 students by the end of the school year.</p>
            <p>In 1953, the 405 freeway was constructed on that property and the school was moved to the 2 acre property on Sherman Way &amp; Haskell Ave.   The first class was held in the original farm house.  Besides the farm house there was a barn, guest house and corral.  There were horses, goats, pigs and chickens residing on the farm.  Today the campus covers approximately 4 acres and features over 22,000 square feet of indoor space. </p>
            <p>In 1959, the farm house was moved to the back of the school property and the elementary buildings were constructed.</p>
            <p>In 1960, a pool was added to enhance the summer camp program.</p>
            <p>In 1978, the property was expanded to add a Gymnasium, theater, middle school classrooms and a physical science lab.  </p>
            <p>In 1979, the property was expanded to add a sports field, preschool playground and 4 new classrooms.  </p>
            <p>In 1981, a Library and upper grade cafeteria was constructed. </p>
            <p>In 1983, a classroom was converted into a computer lab with Apple IIe Computers.</p>
            <p>In 2000, the computer lab was expanded to a larger space and a fine arts center was built above the elementary office.</p>
            <p>In the summer of 2019, a state-of-the-art S.T.E.A.M Lab and school garden was added to the elementary campus.  The preschool was completely reimagined; The facility was updated and transformed into an Early Childhood Center (ECC), preparing the students for the advanced curriculum that is taught in Valley School’s K-8th grade programs.</p> */}
            <h3>Early Childhood Center (ECC)</h3>
            <EccBullets showIntro />
            <p>Our caring and compassionate teachers create a loving atmosphere that nurtures trusting relationships between students, parents, and teachers. As we bridge the gap from home to school, our students build a positive self-image, learn strong social-emotional skills, and ultimately become bright independent thinkers. Our clean and safe setting allows children to explore, discover, and learn through new technology, hands-on activities, and an age appropriate S.T.E.M. Lab that we call “Circuit Training for the Brain”.  Our ECC students graduate with a strong foundation and are prepared to master the 21st century skills necessary for future success in our ever changing world.   </p>
            <p>We have designed our program to balance structure and free choice. Classroom routines introduce new ideas, encourage involvement and build self-confidence, allowing each child to feel success without pressure. Recognizing that children grow in unpredictable stages, we treat each child as an individual, working from the level each child has attained and moving forward one step at a time. Activities are planned to emphasize the process rather than the product, fostering a sense of accomplishment and pride.  The student body is racially and ethnically mixed. With representatives from almost every culture.  In fact, Valley School considers its ethnic diversity one of its major strengths!</p>
          </div>
          {/* <div className="col-md-4">
            <Image src="2019-20 Preschool Photo Shoot-211.jpg" style={{borderRadius: "20px"}} />
          </div> */}
        </div>
      </div>
    </Layout>
  )
}

export default AboutUsPage
