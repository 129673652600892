import React from "react"
import { Helmet } from 'react-helmet'

const EccIntro = (
  <div className="ecc-orange-center">
    <p>Preschool 2 to 4 year olds</p>
    <p>Transitional Kindergarten (TK) 4 to 5 year olds</p>
  </div>
)

export const EccBullets = ({ showIntro = false }) => {
  return (
    <>
      <Helmet>
        <style type="text/css">{`
          .ecc-orange-center p {
            text-align: center;
            font-style: italic;
            line-height: 1;
            margin: .5em;
            color: var(--brochure-color-red);
          }
          .ecc {
            padding: 0;
            text-align: center;
          }
          .ecc li {
            display: inline;
          }
          .ecc li:nth-child(n+2)::before {
            content: " • ";
            color: var(--brochure-color-red);
            font-size: 1.2em;
            font-weight: 600;
          }
        `}
        </style>
      </Helmet>
      {showIntro ? EccIntro : ''}
      <ul className="ecc">
        <li>Small Class Sizes</li>
        <li>Fine Art &amp; Music</li>
        <li>Extended Before &amp; After School Care 6:30am-6:30pm</li>
        <li>Closed Campus</li>
        <li>Modern Playground &amp; Classrooms</li>
        <li>Full Time ECC includes Hot Lunch + 2 Snacks</li>
        <li>Potty Training Assistance</li>
        <li>Summer Camp</li>
      </ul>
    </>
  )
}